import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import Callout from "@components/narcolepsy/CallOut"
import TwoColumns from "@components/twoColumnsTextCta"
import LearnMore from "@components/learnMore"
import { Heading2 } from "@styles/ui"
import BgImageSection from "@components/narcolepsy/BgImageSection"
import PatientVoice from "@components/PatientVoice"

// Assets
import clipboardImage from "@images/talk-to-your-doctor/clipboard.svg"

const learnMoreCtas = [
  {
    text: "Learn how to get XYWAV",
    url: "/narcolepsy/getting-xywav/",
  },
  {
    text: "Find out about the available support for people taking XYWAV",
    url: "/narcolepsy/patient-support-programs/",
  },
]

const TalkToYourDoctorPage = ({ location, data }) => {
  return (
    <Layout location={location} exitModalColour="orange">
      <Seo
        title="Talking To Your Doctor About XYWAV® and Narcolepsy"
        description="Learn how to have a conversation with your doctor about EDS and/or cataplexy and find out if XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution may be right for you. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      {/* Static hero */}
      <StaticHero eyebrow="TALKING TO YOUR DOCTOR" gradient="orange">
        <>
          Talk to your doctor about the <br tw="hidden md:block" />
          <nobr>high-sodium</nobr> elephant in the room
        </>
      </StaticHero>

      <BgImageSection
        imageData={data}
        isWhiteBg
        widerImage
        mobileAspectRatio={"90%"}
        altText="Patient speaking to a doctor with an elephant (made of salt) in the room"
      >
        <>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md) xl:before:(mb-6)"></div>
          <h2 css={[Heading2, tw`md:(max-w-[350px]) lg:max-w-[465px]`]}>
            Having an informed discussion&mdash;including talking about sodium
            intake&mdash;is important for people with narcolepsy
          </h2>
        </>
      </BgImageSection>

      <section tw="pb-[25px] md:py-[50px]">
        <Container grid>
          <div tw="md:col-span-6">
            <p>
              Narcolepsy is a chronic, complicated, and potentially disabling
              condition. Since there is no cure for narcolepsy, long-term
              management of symptoms, such as excessive daytime sleepiness (EDS)
              and cataplexy, may be needed.
            </p>
            <p tw="mt-5 md:mt-8">
              As you may know, sodium intake is a modifiable risk factor for
              cardiovascular disease. With 131 mg of sodium per maximum
              recommended 9 g nightly dose, XYWAV is a low-sodium oxybate
              treatment.
            </p>
          </div>
          <div tw="mt-5 md:(col-span-6 my-0)">
            <p>
              By choosing low-sodium XYWAV over XYREM<sup>&reg;</sup>
              &nbsp;(sodium&nbsp;oxybate) oral solution, a high sodium oxybate,
              you can treat your cataplexy and/or excessive daytime sleepiness
              in narcolepsy while also reducing your cardiovascular risk due to
              excessive sodium intake. That&apos;s something to remember for
              people with narcolepsy who are already <strong>2.5x</strong> more
              likely* to have a stroke<sup>&dagger;</sup> and{" "}
              <strong>1.6x</strong> more likely* to have a heart attack.
              <sup>&dagger;</sup>
            </p>

            <p tw="ml-1 mt-5 text-[12px] md:(text-xs mt-[25px])">
              <span tw="relative -ml-1">*</span>Odds ratio.
            </p>
            <p tw="ml-1 text-[12px] md:(text-xs)">
              <sup tw="relative -ml-1">&dagger;</sup>A retrospective analysis of
              US medical claims data for 9312 people with narcolepsy and a
              control group of 46,559 without narcolepsy.
            </p>
          </div>
        </Container>
      </section>

      <PatientVoice
        quote={
          <>
            I was nervous about starting a new treatment; how it would fit in my
            schedule, what possible side effects I could have, whether I would
            be able to take it since I was still taking a stimulant, and just
            the stress of a new medication. But I&apos;m so happy I talked
            through my concerns with my doctor and decided to take the
            leap.&rdquo;
          </>
        }
        patient={"Grace"}
        diagnosis={"Diagnosed with narcolepsy type 1."}
        imageData={data}
        id="grace-doctor-quote"
        footnote="Compensated for her time. Results may vary."
      />

      {/* Orange callout */}
      <Callout
        theme="orange"
        heading="The Doctor Discussion Guide can help you have a more informed discussion with your doctor."
        id="ddg"
      ></Callout>

      {/* Doctor discussion guide */}
      <section tw="py-[30px]">
        <Container grid>
          <div tw="mb-[30px] md:(col-span-6 mb-0 flex items-center)">
            <img
              src={clipboardImage}
              tw="mx-auto max-w-[157px] md:(max-w-[370px]) lg:(mx-[unset] max-w-[470px])"
              alt=""
            />
          </div>
          <div tw="md:(col-span-6 flex items-center)">
            <div>
              <h2 css={[Heading2, tw`mb-[15px] md:mb-[25px]`]}>
                This handy guide can help you get the conversation started and
                address important considerations, including:
              </h2>
              <ul tw="list-disc [padding-inline-start: 20px] text-sm md:(flex-1 text-xl)">
                <li tw="mb-2.5 md:mb-[15px]">Two types of narcolepsy</li>
                <li tw="mb-2.5 md:mb-[15px]">
                  Cataplexy and EDS in narcolepsy
                </li>
                <li tw="mb-2.5 md:mb-[15px]">Long-term health in narcolepsy</li>
                <li tw="mb-2.5 md:mb-[15px]">Transitioning from XYREM</li>
                <li tw="mb-2.5 md:mb-[15px]">Sodium content of XYWAV</li>
                <li tw="mb-2.5 md:mb-[15px]">What to expect/side effects</li>
                <li>Available treatment support for XYWAV</li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

      {/* Orange callout */}
      <Callout
        theme="orange"
        cta={{
          text: "download the guide",
          url: "/pdf/doctor-discussion-guide-narcolepsy.pdf",
          isDownload: true,
        }}
      >
        <h2 css={[Heading2, tw`text-white mb-[25px]`]}>
          Talk to your doctor about XYWAV today. Download the Doctor Discussion
          Guide to help prepare you for the discussion.
        </h2>
        <p>Remember to take it with you to your next visit!</p>
      </Callout>

      <section tw="pt-[60px] md:pt-[50px]">
        <TwoColumns
          heading={
            <>
              <span tw="md:(block max-w-[470px])">
                Have questions about getting XYWAV? Hear from a doctor who
                specializes in sleep medicine
              </span>
            </>
          }
          imageData={data.colImage}
          imgFirst
          cta={{
            url: "/narcolepsy/educational-webinars/?video=2#webinars",
            text: "getting xywav",
            mdSmall: true,
          }}
        >
          <p tw="mt-[15px] md:(mt-[25px] max-w-[470px])">
            Learn about the steps you can take to get XYWAV, whether you have
            never taken or are currently taking XYREM.
          </p>
        </TwoColumns>
      </section>
      <LearnMore ctas={learnMoreCtas} colour="orange" buttonColour="seashell" />
    </Layout>
  )
}

export default TalkToYourDoctorPage

export const query = graphql`
  query {
    bgImageSection: file(
      relativePath: { eq: "talk-to-your-doctor/bg-image-section.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: { eq: "talk-to-your-doctor/bg-image-section-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    colImage: file(
      relativePath: { eq: "talk-to-your-doctor/have-questions.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 470, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "talk-to-your-doctor/grace-desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 425, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "talk-to-your-doctor/grace-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
